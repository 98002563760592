/* eslint-disable @typescript-eslint/no-unsafe-call */
"use client";

import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import { env } from "@/env.mjs";
import { useEffectOnce } from "@/hooks/useEffectOnce";
import { createGenericContext } from "@/utils/createGenericContext";
import { useSession } from "next-auth/react";
import { type PageContent } from "./i18n";
import useLocalStorage from "./useLocalStorage";
const t = {
  cookies: {
    title: {
      no: "Vi bruker cookies",
      en: "We use cookies",
      dk: "Vi bruger cookies"
    },
    description: {
      no: "Vi bruker cookies for å huske valgene dine og gi deg en bedre brukeropplevelse. Mer informasjon finner du i vår",
      en: "We use cookies to remember your choices and give you a better user experience. More information can be found in our",
      dk: "Vi bruger cookies til at huske dine valg og give dig en bedre brugeroplevelse. Mere information kan findes i vores"
    },
    pp: {
      en: "Privacy policy",
      no: "personvernerklæring",
      dk: "privatlivspolitik"
    },
    important: {
      no: "Kun nødvendige",
      en: "Only necessary",
      dk: "Kun nødvendige"
    },
    all: {
      no: "Godta alle",
      en: "Accept all",
      dk: "Accepter alle"
    }
  }
} satisfies PageContent;

// For some reason global declaration did not work. Having it here should be sufficient though as other components will use exports from the provider
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}
function useNavigationEvent(onEvent: (url: string) => void) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    const url = `${pathname || ""}${searchParams?.toString() || ""}`;
    onEvent(url);
  }, [pathname, searchParams, onEvent]);
}
const [useAnalyticsProvider, AnalyticsContextProvider] = createGenericContext<{
  // userId: string | null;
  // userHmac: string | null;
}>();
function AnalyticsProvider(props: {
  children: ReactNode;
}) {
  const session = useSession();
  const user = session.data?.user;
  const [cookiesAccepted, setCookiesAccepted, isLoading] = useLocalStorage<string | null>("vouchCookiesAccepted", null);
  const [displayCookieBanner, setDisplayCookieBanner] = useState<boolean>(false);
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  // setup no matter what
  useEffectOnce(() => {
    if (typeof window !== "undefined" && "Intercom" in window && !window.Intercom.booted) {
      window?.Intercom?.("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: env.NEXT_PUBLIC_INTERCOM_APP_ID,
        custom_launcher_selector: "#open-intercom",
        user_id: user?.id // Unique ID for your user
      });
    }
  });
  useEffect(() => {
    if (session.status === "authenticated") {
      setCookiesAccepted("all");
      setDisplayCookieBanner(false);
    } else if (session.status === "unauthenticated") {
      if (cookiesAccepted === null) {
        setDisplayCookieBanner(true);
      } else {
        setDisplayCookieBanner(false);
      }
    }
  }, [session.status, cookiesAccepted, setCookiesAccepted]);
  useEffectOnce(() => {
    if (cookiesAccepted && !analyticsInitialized) {
      setAnalyticsInitialized(true);
    }
  }, [cookiesAccepted, analyticsInitialized]);
  const onEvent = useCallback(() => {
    if (analyticsInitialized) {
      window.Intercom?.("update");
    }
  }, [analyticsInitialized]);
  useNavigationEvent(onEvent);
  return <AnalyticsContextProvider value={{}} data-sentry-element="AnalyticsContextProvider" data-sentry-component="AnalyticsProvider" data-sentry-source-file="AnalyticsProvider.tsx">
      {props.children}
      {!isLoading && displayCookieBanner && <CookieConsentBanner setCookieAcceptance={boolean => {
      setCookiesAccepted(boolean ? "necessary" : "all");
      setDisplayCookieBanner(false);
    }} />}
    </AnalyticsContextProvider>;
}
export { AnalyticsProvider, useAnalyticsProvider };
const CookieConsentBanner = (props: {
  setCookieAcceptance: (necessaryOnly?: boolean) => void;
}) => {
  const {
    lang
  } = useLocaleProvider();
  useEffect(() => {
    const htmlRoot = document.getElementsByTagName("html")[0];
    /*
         To avoid potential crashes of null/undefined selections, you may want 
         to save the selection as a variable and check if the selection exists  
         before attempting to add a style. For example, type guarding the 
         selection to make sure its defined before attempting to access 
         Element properties:
           const node = document.getElementById("container");
         if (node) node.style = "...";
      */
    if (htmlRoot && htmlRoot.getBoundingClientRect().width < 1024) {
      htmlRoot.style.overflow = "hidden";
      return () => {
        htmlRoot.style.overflow = "";
      };
    }
  }, []);
  return <>
      <div className="pointer-events-none fixed bottom-0 top-0 z-50 h-dvh w-dvw bg-black/80 lg:hidden" />
      <section className="fixed bottom-0 z-[60] w-full p-2 pr-20 lg:w-auto lg:pr-0">
        <div className="container mx-auto border border-black bg-white p-4 py-2 md:p-8 md:py-4">
          <div className="flex max-w-7xl flex-col items-start gap-2">
            <Text.H5 data-sentry-element="unknown" data-sentry-source-file="AnalyticsProvider.tsx">{t.cookies.title[lang]}</Text.H5>
            <Text.P small data-sentry-element="unknown" data-sentry-source-file="AnalyticsProvider.tsx">
              {t.cookies.description[lang]}{" "}
              <a className="text-xs underline" href="https://vouchcareers.notion.site/Privacy-Policy-cdca4246ab3748bb979669c09adf5132?pvs=4" target="_blank">
                {t.cookies.pp[lang]}
              </a>
              .
            </Text.P>
            <div className="mt-2 flex w-full flex-wrap gap-2 md:items-end md:justify-end">
              <Button variant="ghost" bordered onClick={() => props.setCookieAcceptance(true)} size="sm" data-sentry-element="Button" data-sentry-source-file="AnalyticsProvider.tsx">
                {t.cookies.important[lang]}
              </Button>
              <Button onClick={() => props.setCookieAcceptance()} size="sm" data-sentry-element="Button" data-sentry-source-file="AnalyticsProvider.tsx">
                {t.cookies.all[lang]}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>;
};